import { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserRole } from "../store/Slices/auth/authoriseSlice";
import {
  Box,
  IconButton,
  // InputBase,
  Menu,
  MenuItem,
  Badge,
  Avatar,
  Typography,
  Tooltip,
  Divider,
  useMediaQuery,
  // InputBase,
  // Paper,
} from "@mui/material";

import {
  LightModeOutlined as LightModeOutlinedIcon,
  DarkModeOutlined as DarkModeOutlinedIcon,
  NotificationsOutlined as NotificationsOutlinedIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  // Search as SearchIcon,
  WidgetsOutlined as WidgetsOutlinedIcon,
  MenuOutlined as MenuOutlinedIcon,
} from "@mui/icons-material";

import { useTheme } from "@mui/material/styles";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
// import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import Profile from "../assets/imgs/profile.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorModeContext, tokens } from "../theme";

import { Icon } from "@iconify/react";

import { AuthService } from "../services/dataService";
import clearAllSlicesThunk from "../store/Slices/auth/logout";
import Searchbar from "./top/Searchbar";
import NotificationsPopover from "./top/NotificationsPopover";

// import NotificationDropdown from "../components/common/NotificationDropdown";
// import Searchbar from "./top/Searchbar";
const HEADER_MOBILE = 54;
const HEADER_DESKTOP = 64;

const Topbar = ({ onToggleDrawer }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [notificationCount, setNotificationCount] = useState(0);
  const [roles, setRoles] = useState([]);
  const [isMultiRole, setIsMultiRole] = useState(false);
  const showIconButton = useMediaQuery(theme.breakpoints.down("lg"));
  const showMobileHeader = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSwitch, setIsLoadingSwitch] = useState(false);
  const userRole = localStorage.getItem("Authorise");
  const dispatch = useDispatch();
  const isActiveRole = useSelector((state) => state.authorise.userRole);
  const userName = localStorage.getItem("userName");
  const formattedUserName = userName
    ? userName
        .split("@")[0] // Get the part before '@'
        .replace(/(?:^|\s|\.)(.)/g, (match, letter) => letter.toUpperCase()) // Capitalize the first letter after space or dot
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add a space between camelCase
    : "";

  function handleSuccessMessage(message) {
    toast.success(message, {
      autoClose: 2000,
      position: "top-center",
    });
  }

  const handleNotificationClick = () => {
    setNotificationCount(0);
  };

  const navigate = useNavigate();

  const handleSettings = (popupState) => {
    navigate("/settings");
    popupState.close();
  };

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      const response = await AuthService.logout();

      if (response.status === 200) {
        localStorage.clear();
        clearAllSlicesThunk(dispatch);
        handleSuccessMessage("Logout successful");
        // window.location.href = "https://eduskillsfoundation.org/login/";
         navigate("/login");
      } else {
        console.error("Logout failed with status:", response.status);
      }

      return response;
    } catch (error) {
      localStorage.clear();
      clearAllSlicesThunk(dispatch);
      console.error("Logout error:", error);
       navigate("/login");
      //  window.location.href = "https://eduskillsfoundation.org/login/";
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoadingSwitch(true);
      try {
        const response = await AuthService.roles();

        if (response && response?.status === 200) {
          const fetchedRoles = response.data.roles;
          setRoles(fetchedRoles);
          setIsMultiRole(response.data.multi_role);
          const activeRole = fetchedRoles.find((role) => role.status === true);

          if (activeRole) {
            const activeRoleNameModified = activeRole.role_name
              .toLowerCase()
              .replace(/ /g, "_");
            localStorage.setItem("Authorise", activeRoleNameModified);
            dispatch(setUserRole(activeRoleNameModified));
            setIsLoadingSwitch(false);
          }
        } else {
          console.error("Invalid response:", response);
          setIsLoadingSwitch(false);
        }
      } catch (error) {
        console.error("Error fetching data:");
        setIsLoadingSwitch(false);
      }
    }

    fetchData();
  }, [dispatch]);

  const handleRoleSwitch = async (id) => {
    setIsLoadingSwitch(true);
    navigate("/dashboard");

    try {
      const response = await AuthService.switch_role(id);
      const fetchedRoles = response?.data.roles;
      console.log(fetchedRoles);
      setRoles(fetchedRoles);
      setIsMultiRole(response?.data.multi_role);
      const activeRole = fetchedRoles?.find((role) => role?.status === true);
      if (activeRole) {
        const activeRoleNameModified = activeRole?.role_name
          .toLowerCase() // Convert to lowercase
          .replace(/ /g, "_"); // Replace spaces with underscores
        localStorage.setItem("Authorise", activeRoleNameModified);
        dispatch(setUserRole(activeRoleNameModified));

        // navigate("/");
        handleSuccessMessage(`${activeRole?.role_name} switched successfully`);
        setIsLoadingSwitch(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoadingSwitch(false);
    }
  };

  return (
    <Box
      sx={{
        boxShadow: `0px 1px 4px ${colors.grey[900]}`,
        // position:'fixed'
        height: showMobileHeader ? HEADER_MOBILE : HEADER_DESKTOP,
        borderRadius: "0px 0px",
        bgcolor: colors.background[100],
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        height={"100%"}
      >
        <Box display="flex">
          {showIconButton && (
            <IconButton sx={{ mr: 2 }} onClick={onToggleDrawer}>
              <MenuOutlinedIcon />
            </IconButton>
          )}

          {/* SEARCH BAR */}
          {userRole === "staff" && <Searchbar />}
        </Box>

        {/* ICONS */}
        <Box display="flex" alignItems="center">
          {isMultiRole && (
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <Box>
                  <Tooltip title="Roles Switch">
                    <IconButton {...bindTrigger(popupState)} sx={{ mr: 1 }}>
                      <WidgetsOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    {...bindMenu(popupState)}
                    sx={{
                      mt: 2,
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // mb:'4px'
                      }}
                    >
                      <Icon
                        icon="fluent:window-multiple-16-filled"
                        height={15}
                        width={15}
                      />
                      <Typography variant="body2" ml={0.2}>
                        Manage User Roles
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <Box
                      sx={{
                        m: "1px 9px",
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "8px",
                      }}
                    >
                      {roles.map((role) => {
                        if (role) {
                          const isRoleActive =
                            role.role_name.toLowerCase().replace(/ /g, "_") ===
                            isActiveRole;

                          return (
                            <MenuItem
                              key={role.role_id}
                              disabled={isLoadingSwitch}
                              onClick={() => {
                                if (!isRoleActive) {
                                  popupState.close();
                                  handleRoleSwitch(role.role_id);
                                }
                              }}
                              sx={{
                                borderRadius: 1,
                                minWidth: 125,
                                display: "flex",
                                justifyContent: "center",
                                backgroundColor: role.status
                                  ? colors.blueAccent[600]
                                  : "inherit",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                {/* Render the role-specific icon here */}
                                {role.role_name === "Admin" && (
                                  <Icon
                                    icon="ic:baseline-admin-panel-settings"
                                    height={22}
                                    width={22}
                                  />
                                )}
                                {role.role_name === "Talent" && (
                                  <Icon
                                    icon="game-icons:hive-mind"
                                    height={22}
                                    width={22}
                                  />
                                )}
                                {role.role_name === "Staff" && (
                                  <Icon
                                    icon="mdi:people-group"
                                    height={22}
                                    width={22}
                                  />
                                )}
                                {role.role_name === "Manager" && (
                                  <Icon
                                    icon="fa-solid:user-cog"
                                    height={22}
                                    width={22}
                                  />
                                )}
                                {role.role_name === "Talent Module" && (
                                  <Icon
                                    icon="fluent:brain-circuit-20-filled"
                                    height={22}
                                    width={22}
                                  />
                                )}
                                {role.role_name === "Educator" && (
                                  <Icon
                                    icon="fa-solid:user-tie"
                                    height={22}
                                    width={22}
                                  />
                                )}
                                {role.role_name === "Management" && (
                                  <Icon
                                    icon="mdi:user-group"
                                    height={22}
                                    width={22}
                                  />
                                )}
                                {role.role_name === "Spoc" && (
                                  <Icon
                                    icon="solar:user-speak-bold"
                                    height={22}
                                    width={22}
                                  />
                                )}
                                {role.role_name === "Account Manager" && (
                                  <Icon
                                    icon="mdi:account-lock-outline"
                                    height={22}
                                    width={22}
                                  />
                                )}
                                {/* Render the role name */}
                                <Typography variant="subtitle2">
                                  {role.role_name}
                                </Typography>
                              </Box>
                            </MenuItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Box>
                  </Menu>
                </Box>
              )}
            </PopupState>
          )}
          <Tooltip
            title={
              theme.palette.mode === "light"
                ? "Toggle Dark Mode"
                : "Toggle Light Mode"
            }
          >
            <IconButton onClick={colorMode.toggleColorMode} sx={{ mr: 1 }}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                // <Icon icon="moon-loop" />
                <LightModeOutlinedIcon />
                // <Icon icon="moon-to-sunny-outline-loop-transition" />
              )}
            </IconButton>
          </Tooltip>

          {/* <IconButton onClick={handleNotificationClick} sx={{ mr: 1 }}>
            <Badge badgeContent={notificationCount} color="error">
              <NotificationsOutlinedIcon color="action" />
            </Badge>
          </IconButton> */}
          {/* {(userRole === "staff" ||
            userRole === "admin" ||
            userRole === "spoc") && <NotificationsPopover />} */}

          {userRole === "staff" || userRole === "admin" || userRole === "spoc"}

          {/* <NotificationDropdown/> */}

          {/* <NotificationDropdown/> */}

          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <>
                <Tooltip title="Profile">
                  <Avatar
                    alt="Remy Sharp"
                    src={Profile}
                    sx={{ width: 32, height: 32, ml: 1, cursor: "pointer" }}
                    {...bindTrigger(popupState)}
                  />
                </Tooltip>
                <Menu {...bindMenu(popupState)} sx={{ mt: 2.5, px: 3 }}>
                  <Box sx={{ py: 1, px: 2 }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color={colors.blueAccent[200]}
                      sx={{ ml: 0.4, mt: -0.8 }}
                    >
                      {formattedUserName ? formattedUserName : "User Name"}
                    </Typography>
                    <Badge
                      color="secondary"
                      badgeContent="active"
                      variant="dot"
                    >
                      <Typography
                        fontWeight="bold"
                        variant="caption"
                        sx={{
                          bgcolor: colors.grey[900],
                          px: 0.8,

                          borderRadius: 1,
                        }}
                      >
                        {isActiveRole ? isActiveRole : "Email Address"}
                      </Typography>
                    </Badge>
                  </Box>
                  <Divider />
                  <MenuItem
                    onClick={popupState.close}
                    disabled
                    sx={{
                      borderRadius: 1,
                      minWidth: 125,
                      m: "2px 10px",
                      mt: 1,
                    }}
                  >
                    <AccountCircleOutlinedIcon sx={{ mr: 1 }} />
                    Profile
                  </MenuItem>

                  {/* <MenuItem
                    onClick={popupState.close}
                    sx={{
                      borderRadius: 1,
                      minWidth: 125,
                      m: "2px 10px",
                    }}
                  >
                    <ManageAccountsRoundedIcon sx={{ mr: 1 }} />
                    Manage Account
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => handleSettings(popupState)}
                    sx={{
                      borderRadius: 1,
                      minWidth: 125,
                      m: "2px 10px",
                    }}
                    disabled
                  >
                    <SettingsOutlinedIcon sx={{ mr: 1 }} />
                    Settings
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    disabled={isLoading}
                    onClick={handleLogout}
                    sx={{
                      borderRadius: 1,
                      minWidth: 125,
                      m: "3px 10px",
                      "&:hover": {
                        backgroundColor: colors.redAccent[800], // Change to your desired hover color
                      },
                    }}
                  >
                    <LogoutOutlinedIcon sx={{ mr: 1 }} />
                    Logout
                  </MenuItem>
                </Menu>
              </>
            )}
          </PopupState>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
